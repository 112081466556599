define("discourse/plugins/discourse-pkv/discourse/initializers/home-edits", ["exports", "discourse/lib/utilities", "discourse/lib/plugin-api", "ember-addons/ember-computed-decorators"], function (_exports, _utilities, _pluginApi, _emberComputedDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: 'home-edits',
    initialize(container) {
      const currentUser = container.lookup('current-user:main');
      const siteSettings = container.lookup('site-settings:main');
      if (!currentUser || !currentUser.homepage_id) (0, _utilities.setDefaultHomepage)('home');
      (0, _pluginApi.withPluginApi)('0.8.23', api => {
        var _dec, _obj;
        api.changeWidgetSetting('home-logo', 'href', '/categories');
        api.modifyClass('controller:preferences/interface', (_dec = (0, _emberComputedDecorators.default)(), (_obj = {
          userSelectableHome() {
            let core = this._super();
            core.push(...[{
              name: "Home",
              value: 101
            }]);
            return core;
          },
          homeChanged() {
            const homepageId = this.get("model.user_option.homepage_id");
            if (homepageId === 101) {
              (0, _utilities.setDefaultHomepage)("home");
            } else if (homepageId === 102) {
              (0, _utilities.setDefaultHomepage)("assigned");
            } else {
              this._super();
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "userSelectableHome", [_dec], Object.getOwnPropertyDescriptor(_obj, "userSelectableHome"), _obj)), _obj)));
      });
    }
  };
});